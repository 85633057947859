import React, { useEffect, useState } from "react";
import IRExportersLogo from "@/public/icons/IRExportersLogo.svg";
import Image from "next/image";

const AnimateLogoSection = (props) => {
  const { textColor = "white", circleColor = "black" } = props;
  const [activeCircle, setActiveCircle] = useState(0);

  return (
    <div className="my-5 mx-auto w-full relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="158"
        viewBox="0 0 270 158"
        fill="none"
        className="mx-auto"
      >
        <path
          d="M126.5 125C151.629 125 172 104.405 172 79C172 53.5949 151.629 33 126.5 33C101.371 33 81 53.5949 81 79C81 104.405 101.371 125 126.5 125Z"
          stroke={activeCircle == 1 ? "#00A3FF" : circleColor}
          stroke-width="1.5"
          stroke-miterlimit="2.61313"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <animate
            attributeType="XML"
            attributeName="stroke"
            values={`#00A3FF;${circleColor}`}
            dur="0.25s"
            begin="second.end"
            id="third"
          />
        </path>
        <path
          d="M126.5 141C161.018 141 189 113.242 189 79C189 44.7583 161.018 17 126.5 17C91.9822 17 64 44.7583 64 79C64 113.242 91.9822 141 126.5 141Z"
          stroke={activeCircle == 2 ? "#00A3FF" : circleColor}
          stroke-width="1.5"
          stroke-miterlimit="2.61313"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <animate
            attributeType="XML"
            attributeName="stroke"
            values={`#00A3FF;${circleColor}`}
            dur="0.25s"
            begin="first.end"
            id="second"
          />
        </path>
        <path
          d="M126.5 157C169.854 157 205 122.078 205 79C205 35.9218 169.854 1 126.5 1C83.1456 1 48 35.9218 48 79C48 122.078 83.1456 157 126.5 157Z"
          stroke={activeCircle == 3 ? "#00A3FF" : circleColor}
          stroke-width="1.5"
          stroke-miterlimit="2.61313"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <animate
            attributeType="XML"
            attributeName="stroke"
            values={`#00A3FF;${circleColor}`}
            dur="0.25s"
            begin="0s;third.end+3s"
            id="first"
          />
        </path>
        {/* <path
          d="M142.854 62.277C138.834 63.2146 134.62 63.5754 130.329 63.6908C130.179 63.694 130.054 63.7996 130.025 63.9459C129.995 64.0938 130.062 64.2676 130.205 64.2985C132.879 64.8916 135.694 66.3623 137.421 68.088C139.148 69.8138 140.686 72.8477 141.213 75.2982C141.244 75.4428 141.419 75.5078 141.566 75.4786C141.714 75.4493 141.818 75.3242 141.821 75.1747C141.936 70.8847 142.297 66.6743 143.236 62.6589C143.262 62.5484 143.231 62.4411 143.151 62.3599C143.072 62.2802 142.964 62.2494 142.852 62.2754L142.854 62.277Z"
          fill={textColor}
        />
        <path
          d="M109.767 95.3394C110.706 91.3224 111.067 87.112 111.182 82.8236C111.185 82.6741 111.291 82.549 111.437 82.5197C111.585 82.4905 111.759 82.5571 111.79 82.7001C112.384 85.3716 113.856 88.1845 115.583 89.9103C117.31 91.636 120.346 93.1733 122.798 93.6998C122.943 93.7307 123.008 93.9062 122.978 94.0524C122.949 94.2003 122.824 94.3043 122.674 94.3075C118.381 94.4229 114.168 94.7837 110.15 95.7213C110.039 95.7473 109.932 95.7164 109.85 95.6368C109.771 95.5572 109.74 95.4499 109.766 95.3378L109.767 95.3394Z"
          fill={textColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M126.502 67.165C133.043 67.165 138.346 72.4642 138.346 79C138.346 85.5358 133.043 90.835 126.502 90.835C119.962 90.835 114.659 85.5358 114.659 79C114.659 72.4642 119.962 67.165 126.502 67.165ZM126.502 72.1376C130.295 72.1376 133.37 75.2105 133.37 79C133.37 82.7895 130.295 85.8624 126.502 85.8624C122.71 85.8624 119.635 82.7895 119.635 79C119.635 75.2105 122.71 72.1376 126.502 72.1376Z"
          fill={textColor}
        />
        <path
          d="M36.4266 67.9255V72.3862H56.2172V67.9255H36.4266ZM36.4266 76.7851V81.2149H56.2172V76.7851H36.4266ZM36.521 85.6138V90.0745H56.2172V85.6138H36.521Z"
          fill={textColor}
        />
        <path
          d="M84.0347 90.0745L74.9785 78.6522L83.2119 67.9255H76.9104L71.6855 74.5068L66.4606 67.9255H60.1283L77.1641 90.0761H84.0347V90.0745ZM69.4056 85.7389L66.0492 81.5626L59.3363 90.0745H66.2069L69.4056 85.7389Z"
          fill={textColor}
        />
        <path
          d="M99.2346 82.3539C104.079 82.3539 106.517 78.7464 106.517 75.1389C106.517 71.5314 104.079 67.9238 99.2346 67.9238H87.2334V72.3845H98.6638C100.438 72.3845 101.324 73.7771 101.324 75.1373C101.324 76.5299 100.438 77.89 98.6638 77.89H87.2334V82.3507H99.2346V82.3539Z"
          fill={textColor}
        />
        <path
          d="M262.485 90.068C267.329 90.068 269.767 87.0991 269.767 83.4916C269.767 79.8841 267.329 76.6194 262.485 76.6194H258.974C257.2 76.6194 256.314 75.7338 256.314 74.5037C256.314 73.2735 257.2 72.3879 258.974 72.3879H267.947L267.951 67.9272H258.403C253.559 67.9272 251.121 70.8978 251.121 74.5037C251.121 78.1112 253.67 81.3758 258.514 81.3758H259.423H261.914C263.688 81.3758 264.575 82.3232 264.575 83.4916C264.575 84.66 263.688 85.6074 261.914 85.6074H251.925V90.068H262.483H262.485Z"
          fill={textColor}
        />
        <path
          d="M157.564 81.7527L163.423 90.0744H170.104L163.296 81.3091C166.272 80.2642 167.76 77.5439 167.76 74.8529C167.76 71.3721 165.353 67.9238 160.478 67.9238H147.116V72.3845H159.561C161.587 72.3845 162.6 73.6195 162.6 74.8529C162.6 76.0879 161.587 77.3213 159.561 77.3213H147.116V81.7511H157.564V81.7527Z"
          fill={textColor}
        />
        <path
          d="M171.91 67.9255V72.3553H196.641V67.9255H171.91ZM181.599 90.0761H186.951V75.8377H181.599V90.0761Z"
          fill={textColor}
        />
        <path
          d="M199.869 67.9255V72.3862H219.66V67.9255H199.869ZM199.869 76.7851V81.2149H219.66V76.7851H199.869ZM199.963 85.6138V90.0745H219.658V85.6138H199.963Z"
          fill={textColor}
        />
        <path
          d="M234.746 81.7527L240.605 90.0744H247.287L240.478 81.3091C243.456 80.2642 244.942 77.5439 244.942 74.8529C244.942 71.3721 242.535 67.9238 237.66 67.9238H224.296V72.3845H236.741C238.767 72.3845 239.78 73.6195 239.78 74.8529C239.78 76.0879 238.767 77.3213 236.741 77.3213H224.296V81.7511H234.744L234.746 81.7527Z"
          fill={textColor}
        />
        <path
          d="M0.000244141 67.9255V72.3862H5.352V67.9255H0.000244141ZM0.000244141 90.0761H5.352V75.8377H0.000244141V90.0761Z"
          fill={textColor}
        />
        <path
          d="M20.5616 81.7527L26.4191 90.0744H33.1011L26.2923 81.3091C29.2698 80.2642 30.7562 77.5439 30.7562 74.8529C30.7562 71.3721 28.3494 67.9238 23.4741 67.9238H10.1118V72.3845H22.557C24.5832 72.3845 25.5963 73.6195 25.5963 74.8529C25.5963 76.0879 24.5832 77.3213 22.557 77.3213H10.1118V81.7511H20.5616V81.7527Z"
          fill={textColor}
        /> */}
      </svg>
      <Image className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" width={260} height={36} src={textColor=="white"?"/images/logonamelight.png":"/images/logoname.png"}></Image>
    </div>
  );
};

export default AnimateLogoSection;
